import React, { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Model from './Model'

export default function Viewer() {
  const ref = useRef()
  const [texture, setTexture] = useState()

  const loadImage = (file) => {
    return new Promise((resolve) => {
      const image = new Image()

      image.onload = () => {
        resolve(image)
      }

      image.src = file
    })
  }

  useEffect(() => {
    const handleDrop = async (e) => {
      e.preventDefault()

      const dt = e.dataTransfer
      const files = dt.files

      if (files.length) {
        const img = await loadImage(window.URL.createObjectURL(files[0]))
        const data = img.src

        setTexture(data)
      }
    }

    function preventDefaults (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
      document.body.addEventListener(eventName, preventDefaults, false)
    })

    document.body.addEventListener('drop', handleDrop, false)
  }, [setTexture])

  return (
    <Canvas shadows={false} dpr={[0, 2]} camera={{ fov: 45 }}>
      <ambientLight intensity={0.2} />
      <pointLight position={[-10, 0, 0]} intensity={.5} color="white" />
      <pointLight position={[10, 10, 0]} intensity={1} color="white" />

      <Suspense fallback={null}>
        <Stage contactShadow={false} shadows={false} contactShadowOpacity={0} controls={ref} preset="rembrandt" intensity={0}  environment="studio">
          <Model texture={texture} />
        </Stage>
      </Suspense>
      <OrbitControls ref={ref} />
    </Canvas>
  )
}
